import { Injectable } from '@angular/core';
import { config } from '@tag/graphql';
import type { GraphQLParams, RelatedRequirement } from '@tag/graphql';
import { Query, gql } from 'apollo-angular';

export interface Response {
  requirements: { items: RelatedRequirement[]; totalCount: number };
}

@Injectable({
  providedIn: 'root',
})
export class RequirementsGQL extends Query<
  Response,
  GraphQLParams<'relatedRequirement'>
> {
  document = gql(config.relatedRequirement.query);
}
