import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Store } from '@ngxs/store';
import type { Attachment } from '@tag/graphql';
import { Observable } from 'rxjs';

import { SubscriptionsComponent } from '@helpers/subscriptions-component';
import { AttachementTagModel } from '@stores-models/attachment-tag-model';
import { AuthState } from '@stores-states/authentication.state';
import { AttachmentsGQL } from '@shared/apollo/queries/attachment';

@Injectable({
  providedIn: 'root',
})
export class AttachmentService extends SubscriptionsComponent {
  constructor(
    private attachmentsService: AttachmentsGQL,
    private httpClient: HttpClient,
    private readonly store: Store
  ) {
    super();
  }

  get company(): string {
    return this.store.selectSnapshot(AuthState.company);
  }

  getAttachmentsByNo(no: string): Observable<AttachementTagModel[]> {
    const company = this.store.selectSnapshot(AuthState.company);
    const filter = `No eq '${no}' and File_Name ne ''`;

    throw new Error('Not implemented');

    // return this.attachmentsService.AttachmentsGet(company, filter).pipe(
    //   map((atts) => this.mapApiToTagModel(atts)),
    //   tap((tagModel) => this.fetchPicture(tagModel))
    // );
  }

  isFileImage(file: File): string {
    if (file && file.type.split('/')[0] === 'image') return 'Picture';
    return 'File';
  }

  mapApiToTagModel(attachments: Attachment[]): AttachementTagModel[] {
    return attachments.map((att) => ({
      id: this.buildAttachmentId(
        att.no ?? '',
        att.noLineNo ?? 0,
        att.lineIncrement ?? 0
      ),

      cardPicture: att.cardPicture,
      createdBy: att.createdBy,
      creationTime: att.creationTime,
      description: att.description,
      fileName: att.fileName,
      filePath: att.filePath,
      format: att.format,
      isUrl: att.isUrl,
      lineIncrement: att.lineIncrement,
      model: att.model,
      advancedAttachmentType: att.advancedAttachmentType,
      no: att.no,
      noLineNo: att.noLineNo,
      sourceType: att.sourceType,

      assetPath: this.getAssetPath(att.fileName),
      type: att.format?.startsWith('image/')
        ? 'Picture'
        : att.isUrl
        ? 'Link'
        : 'File',
    })) as AttachementTagModel[];
  }

  private fetchPicture(attachments: AttachementTagModel[]): void {
    attachments.forEach((element) => {
      if (element.filePath && element.format?.startsWith('image/'))
        this.httpClient
          .get(element.filePath, { responseType: 'blob' })
          .subscribe((x: Blob) => {
            element.blob = URL.createObjectURL(x);
          });
    });
  }

  /**
   * Get icon image of the attachement other then an image to preview like pdf, word, excel file
   *
   * @param attachment
   *
   * @return asset path as a string
   */
  private getAssetPath(fileName?: string): string {
    let assetPath = '/assets/img/attachments/';
    if (fileName) {
      const split = fileName.split('.');
      const format = split[split.length - 1];
      switch (format) {
        case 'xlsx':
          assetPath += 'Excel_256x256.png';
          break;
        case 'xls':
          assetPath += 'Excel_256x256.png';
          break;
        case 'doc':
          assetPath += 'Word_256x256.png';
          break;
        case 'docx':
          assetPath += 'Word_256x256.png';
          break;
        case 'ppt':
          assetPath += 'PowerPoint_256x256.png';
          break;
        case 'pptx':
          assetPath += 'PowerPoint_256x256.png';
          break;
        case 'zip':
          assetPath += 'zip_96px.png';
          break;
        case 'mp3':
          assetPath += 'mp3_96px.png';
          break;
        case 'pdf':
          assetPath += 'pdf_96px.png';
          break;
        default:
          assetPath += 'file_80px.png';
          break;
      }
      return assetPath;
    }
    return '';
  }

  /**
   * Gets attachment state object id
   *
   * @param documentNo
   * @param documentLineNo
   * @param lineNo
   * @returns feedback state object id
   */
  private buildAttachmentId(
    documentNo: string,
    documentLineNo?: number,
    lineNo?: number
  ): string {
    return documentNo + (documentLineNo ?? '') + (lineNo ?? '');
  }
}
